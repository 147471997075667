import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from "react-dom";
import Product from '../services/products';
import History from '../services/history';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Image from 'material-ui-image';
import DraftsIcon from '@material-ui/icons/Drafts';
import Icon from '@material-ui/core/Icon';
import ReactPlayer from 'react-player';
import Button from '@material-ui/core/Button';
import { ButtonBase } from '@material-ui/core';
import HtmlToReactParser from 'html-to-react';
import Chip from '@material-ui/core/Chip';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Paper from '@material-ui/core/Paper';
import RelatedProduct from '../components/products/related';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import MenuItem from '@material-ui/core/MenuItem';
import { UserContext } from '../context/UserContext';
import { useJwt } from 'react-jwt';
import { findDOMNode } from 'react-dom';
import screenfull from 'screenfull'
// import { Document, Page } from 'react-pdf';
import IconButton from '@material-ui/core/IconButton';
import Slider from '@material-ui/core/Slider';
import Slide from '@material-ui/core/Slide';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import { CartContext } from '../context/CartContext';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import SubscriptionService from '../services/subscription';
import Classes from '../services/classes';
import { AuthContext } from '../context/AuthContext';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { shadows } from '@material-ui/system';
import AuthForm from '../components/auth/SingleSignIn';
import Checkout from '../components/checkout/checkout';
import Wallet from '../components/checkout/wallet';
import { Link, Redirect } from 'react-router-dom'
import { useClassesAvailable } from '../hooks'
// import { useNavigation } from '@react-navigation';
import Comments from '../components/products/comments';
import {
    FacebookIcon,
    FacebookShareButton,
    TelegramShareButton,
    TelegramIcon,
    WhatsappShareButton,
    WhatsappIcon
} from 'react-share';
import './css/search.css';
import './css/preview.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useLocation,
    useHistory
} from "react-router-dom";
// import { withNavigation } from 'react-navigation';
import SearchResult from '../components/products/search';

import { pdfjs } from 'react-pdf';

import { slideInRight } from 'react-animations';
import styled, { keyframes } from "styled-components";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    aboutContainer: {
        padding: 30,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            marginTop: 15, marginBottom: 15
        }
    },
    navButtons: {
        backgroundColor: 'white',
        padding: 2,
        height: 25,
        fontSize: 14,
        marginRight: 5,
        width: 30,
        marginLeft: 5,
        borderRadius: 5
    },
    searchWrapper: {
        padding: 30,
        paddingBottom: 0,
        backgroundColor: '#062c32',
        [theme.breakpoints.down('md')]: {
            padding: 15,
            paddingBottom: 0
        }
    },
    resultHeader: {
        borderColor: 'black',
        border: 1,
        m: 1,
    },
    materialTitle: {
        color: 'white'
    },
    imageWrapper: {
        width: '100%',
        opacity: 0.7
    },
    animatedBtn: {
        backgroundColor: '#e98e19',
        padding: 12,
        fontSize: 16,
        color: 'white',
        borderRadius: 5
    },
    animatedBtnBox: {
        position: 'absolute',
        top: 10,
        // width: '20%',
        right: 10,
        zIndex: 5,
    },
    materialsWrapper: {
        paddingLeft: 10,
        // backgroundColor: '#000',
        height: '90%',
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            marginTop: 20,

        },
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 20,
        }
    },
    materialsActions: {
        paddingLeft: 0,
        display: 'flex',
        // alignItems: 'flex-start',
        flexDirection: 'column',
        // justifyContent: ''
    },
    imageHolder: {
        borderRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        overflow: 'hidden',
        backgroundColor: 'black',
        position: 'relative',
        minHeight: '90%',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'auto'
        }
    },
    heading: {
        color: 'white',
        marginBottom: 0,
        backgroundColor: '#041c20',
        padding: 10,
        fontSize: 20,
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
            backgroundColor: 'transparent'
        }
    },
    playIcon: {
        color: 'white',
        fontSize: 29
    },
    visibleIcon: {
        color: '#FA9C24',
        fontSize: 18
    },
    lockIcon: {
        color: '#E0E0E0',
        fontSize: 18,
        marginLeft: 7
    },
    materialHeading: {
        marginBottom: 10,
        paddingLeft: 0,
        color: 'white',
        fontSize: 18
    },
    item: {
        marginBottom: 0,
        padding: 5,
        borderBottom: 'solid',
        borderBottomWidth: 1,
        borderBottomColor: '#052a30'
    },
    materialsInner: {
        padding: 0,
        height: '100%',
    },
    imageRipple: {
        width: '100%',
        height: '80vh',
        [theme.breakpoints.down('md')]: {
            height: 'auto'
        }
    },
    buttonIconText: {
        marginRight: 5,
        textAlign: 'left'
    },
    removeIconText: {
        marginRight: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#be3030',
        color: 'white',
        "&:hover": {
            backgroundColor: '#992424'
        },
        textAlign: 'left',
        width: '100%',
        padding: 8,
        borderRadius: 5
    },
    buyNowBtn: {
        marginRight: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#fa9c24',
        color: 'white',
        "&:hover": {
            backgroundColor: '#b5721d'
        },
        textAlign: 'left',
        width: '100%',
        padding: 8,
        borderRadius: 5
    },
    addToCartBtn: {
        marginRight: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#1a95ac',
        color: 'white',
        "&:hover": {
            backgroundColor: '#147588'
        },
        textAlign: 'left',
        width: '100%',
        padding: 8,
        borderRadius: 5
    },

    buttonIcon: {
        width: 60,
        minWidth: 10
    },
    aboutWrapper: {
        paddingRight: 20,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
            marginBottom: 15
        }
    },
    description: {
        paddingTop: 15
    },
    stats: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 20
    },
    stasWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    tagWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    levelTag: {
        backgroundColor: '#e8e8e8',
        padding: 6,
        marginRight: 4,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 20
    },
    statIcon1: {
        fontSize: 45,
        marginBottom: 10,
        color: '#00c0e2'
    },
    statIcon2: {
        fontSize: 45,
        marginBottom: 10,
        color: '#fa9c24'
    },
    statIcon3: {
        fontSize: 45,
        marginBottom: 10,
        color: '#3f51b5'
    },
    productTagHeading: {
        marginTop: 20,
        marginBottom: 10
    },
    tag: {
        marginRight: 5,
        marginBottom: 5
    },
    relatedProduct: {
        marginLeft: 0,
        marginTop: 30
    },
    speedIcon: {
        color: 'white',
        fontSize: 20
    },
    videoControls: {
        position: 'absolute',
        backgroundColor: '#282828e0',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        zIndex: 10,
    },
    seekBar: {
        width: '96%',
        height: 10,
        padding: 0,
        marginLeft: '2%',

    },
    leftControls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10
    },
    iconControls: {
        color: 'white'
    },
    volumeControl: {
        display: 'flex',
        alignItems: 'center',
        width: '70%',
        [theme.breakpoints.down('xs')]: {
            width: '40%'
        }
    },
    volumeSlider: {
        flex: 1,
        marginLeft: 5,
    },
    playBtn: {
        color: 'white'
    },
    playerControl: {
        marginRight: 10
    },
    rightControls: {
        paddingRight: 20,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 15, paddingRight: 10
        }
    },
    playDuration: {
        fontSize: 14,
        color: 'white',
        marginLeft: 10
    },
    tabWrapper: {
        height: '100%',
        padding: 0,
        '& .MuiBox-root': {
            padding: '0px',
        },
    },
    swipeableWrapper: {
        padding: 0,
        height: '90%',
        backgroundColor: '#083b43',
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        paddingLeft: 0,
        paddingRight: 0,
        [theme.breakpoints.down('sm')]: {
            height: 'auto'
        }
    },
    centreInfoWrapper: {
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0
        }
    },
    avatar: {
        width: 50,
        height: 50,
        borderRadius: 30,
        fontSize: 25,
        backgroundColor: 'white',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 15
    },
    centreName: {
        fontSize: 20,
        color: 'white'
    },
    subscriberFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    subscribeBtn: {
        backgroundColor: '#FA9C24',
        color: 'white',
        "&:hover": {
            backgroundColor: '#c3740f'
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: 45,
            width: 45
        }
    },
    unSubscribeBtn: {
        backgroundColor: '#dadada',
        color: 'black',
        "&:hover": {
            backgroundColor: '#dadada'
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: 45,
            width: 45
        }
    },
    bellIcon: {
        marginRight: 10,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0
        }
    },
    authContainer: {
        backgroundColor: '#061C20',
        height: 'auto',
        paddingTop: 40,
        paddingBottom: 40
    },
    closeIcon: {
        position: 'absolute',
        fontSize: 30,
        color: 'white',
        right: 10,
        top: 10
    },
    courseTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    authWrapper: {
        backgroundColor: '#fff',
        padding: 20,
        borderRadius: 15
    },
    checkIcon: {
        width: '15%'
    },
    textCenter: {
        textAlign: 'center'
    },
    pdfPreviewPage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    pdfLow: {
        color: 'none',
    },
    pdfHigh: {
        color: 'none',
    },
    commenterName: {
        fontSize: 16,
        fontWeight: 500,
        marginBottom: 10
    },
    singleComment: {
        backgroundColor: 'white',
        borderRadius: 10,
        marginBottom: 20,
        boxShadow: "2px 2px 6px #9e9e9e1a"
    },
    commentDate: {
        fontSize: 10,
        color: 'gray'
    },
    comment: {
        color: 'gray'
    },
    commentsContainer: {
        backgroundColor: '#f0faff',
        borderRadius: 10,
        marginTop: 20,
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            padding: 10
        }
    },
    removeBtn: {
        backgroundColor: '#d55555',
        color: 'white',
        borderRadius: 5,
        padding: 6,
        height: 35,
        '&:hover': {
            backgroundColor: '#ad4242'
        }
    },

    addBtn: {
        backgroundColor: '#FA9C24',
        color: 'white',
        borderRadius: 12,
        fontSize: 8,
        padding: 6,
        height: 35,
        '&:hover': {
            backgroundColor: '#a35e05'
        }
    },
    coursePrice: {
        fontSize: 19,
        marginRight: 10,
        color: 'white'
    },
    discountedPrice: {
        textDecoration: 'line-through',
        color: 'gray',
        fontSize: 15,
        marginRight: 5
    },
    buyBtn: {
        backgroundColor: '#f16020',
        color: 'white',
        borderRadius: 12,
        fontSize: 14,
        padding: 6,
        paddingRight: 15,
        paddingLeft: 15,
        height: 35,
        '&:hover': {
            backgroundColor: '#cc501a'
        }
    },
    creditsWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'left'
    },
    credits: {
        color: 'white',
        textAlign: 'left'
    },
    tab: {
        fontSize: 12,
        width: '50%',
        minWidth: '100px',
        padding: 0
    },
    pdfLoader: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    playAgain: {
        background: 'gray'
    },
    fullScreen: {
        color: 'white',
        cursor: 'pointer'
    },
    backButton: {
        marginBottom: 10,
        padding: 5,
        borderRadius: 3,
        backgroundColor: '#FA9D28'
    },
    backLink: {
        color: 'white',
        textDecoration: 'none', fontSize: 16,
        display: 'flex',
        alignItems: 'center',
    },
    backIcon: {
        fontSize: 18,
        marginRight: 5
    },
    loader: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        zIndex: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#00000099'
    }
}))

const PrettoSlider = withStyles({
    root: {
        color: '#e99f0f',
        height: 8,
    },
    thumb: {
        height: 13,
        width: 13,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -4,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 5,
        borderRadius: 4,
    },
    rail: {
        height: 5,
        borderRadius: 4,
    },
})(Slider);

const VolumeSlider = withStyles({
    root: {
        color: '#fff',
        height: 0,
    },
    thumb: {
        backgroundColor: '#fff',
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 2,
        borderRadius: 4,
    },
    rail: {
        height: 2,
        borderRadius: 4,
    },
})(Slider);

export default function ProductView({ navigation }) {
    const [slideIn, setSlideIn] = React.useState(true)
    const SlideInAnimation = keyframes`${slideInRight}`;
    const SlideInDiv = styled.div`
      animation: 1s ${SlideInAnimation};
    `;
    // let history = useHistory();

    const location = useLocation()
    const [product, setProduct] = React.useState([]);
    const [smallScreen, setSmallScreen] = React.useState(true);
    const [productLevels, setProductLevels] = React.useState([]);
    const [fetched, setFetched] = React.useState(false);
    const [playingVideo, playVideo] = React.useState(false);
    const [playAgain, setPlayAgain] = React.useState(false);
    const [cartItem, setCartItem] = React.useContext(CartContext);
    const [videoUrl, setVideo] = React.useState(null);
    const [pdfUrl, setPdf] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [seekTime, setSeekTime] = React.useState(0);
    const [open, setOpen] = React.useContext(AuthContext);
    const [pdfWidth, setPdfWidth] = React.useState(0);
    const [isBuffering, setBuffering] = React.useState(true);
    const [playDuration, setDuration] = React.useState(0);
    const [volume, setVolume] = React.useState(30);
    const [seek, setSeek] = React.useState(0);
    const [checkoutOpen, setCheckoutOpen] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = React.useState(false);
    const [previewStarts, setStart] = React.useState(0);
    const [previewEnds, setEnd] = React.useState(0);
    const [playingMaterial, setPlayingMaterial] = React.useState(null);
    const [previewDurationInSeconds, setPreviewDuration] = React.useState(0);

    const [view, setView] = React.useState(null);
    const [controlsPosition, setControls] = React.useState(0);
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const { products } = React.useContext(UserContext)
    const [usersProducts, setUsersProducts] = products;
    const elementRef = React.useRef(null);
    const { decodedToken, isExpired } = useJwt(token);
    // const { image } = localStorage.getItem('productImage')
    // const { image } = location.state
    const { displayBack } = location.from === 'search' ? true : false;
    // const { id } = localStorage.getItem('productId')
    // const { id } = location.state
    let tags = null
    let loadRelated = false;
    let player = null;
    const classes = useStyles();
    let { term } = useParams();
    const { courseId, title } = useParams();
    const classesAvailable = useClassesAvailable(courseId)
    const [numPages, setNumPages] = React.useState(null);
    const [startTime, setStartTime] = React.useState(0);
    const [endTime, setEndTime] = React.useState(0);
    const [pageNumber, setPageNumber] = React.useState(1);
    const [playbackRate, setPlaybackRate] = React.useState(1);
    const [totalSeek, setTotalSeek] = React.useState(0);
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [videoPlaying, setVideoPlaying] = React.useState(false);
    const [subscriptions, setSubscription] = React.useState([2, 3])
    const history = useHistory();
    const mounted = React.useRef()

    const getSubscriptions = () => {
        SubscriptionService.getSubscription(localStorage.getItem('user_id')).then(res => {
            // console.log(res)
            res.data.map((subscription) => {
                setSubscription(prevSubscriptions => [...prevSubscriptions, subscription.subscribe_to])
            })
        })
    }

    const handleChange = (event, newValue) => {
        // console.log(newValue)
        setValue(newValue);
    };
    console.log(classesAvailable)
    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        // setTimeout(() => {
        //     const canvas = document.getElementsByClassName('react-pdf__Page__canvas');
        //     const ctx = canvas.canvas.getContext('2d')
        //     ctx.filter = 'blur(14px)';
        //     ctx.drawImage(canvas.canvas, 0, 0);
        // }, 500)
    }

    const prevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1)
        }
    }

    const nextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1)
        }
    }

    const firstPage = () => {
        setPageNumber(1)
    }

    const lastPage = () => {
        setPageNumber(numPages)
    }

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const fetchData = React.useCallback(() => {
        let url = window.location.pathname
        const courseId = url.substring(url.lastIndexOf('/') + 1);
        localStorage.setItem('productId', courseId)
        // Here
        if (!isExpired) {
            var data = {
                'user_id': localStorage.getItem('user_id'),
                // 'course_id': id,
                'course_id': localStorage.getItem('productId'),
                'subscriber_id': localStorage.getItem('subscriber_id'),
            }
            History.storeHistory(data);
        }
        Product.getProduct(localStorage.getItem('productId'))
            .then((response) => {
                // console.log(response)
                let courseCover = process.env.REACT_APP_UPLOADS_URL + 'courses/covers/' + response.data[0].subscriber_id + '/' + response.data[0].course_cover;
                if (response.data[0].subscriber_id == 0) {
                    courseCover = process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_COURSE_PATH + 'cover/' + response.data[0].course_cover;
                }
                localStorage.setItem('productImage', courseCover)
                if (response.data[0].subscriber_id == 0) {
                    response.data[0].subscriber_id = response.data[0].course_teacher
                    response.data[0].centre_name = response.data[0].display_name
                    response.data[0].subscriber_type = 'teacher'
                    if (response.data[0].auth_using == 'social') {
                        response.data[0].avatar = response.data[0].profile_picture
                    }
                    else {
                        response.data[0].avatar = process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_DP_PATH + response.data[0].profile_picture;
                    }
                }
                else {
                    response.data[0].subscriber_type = 'centre'
                    response.data[0].avatar = process.env.REACT_APP_UPLOADS_URL + 'admin/subscriber/' + response.data[0].avatar

                    console.log('Product Details', response.data[0])
                }
                setProduct(response.data[0])
                console.log(response.data)
                let levels = response.data[0].course_level.replace(/","/g, ',')
                levels = levels.replace('["', '').replace('"]', '')
                levels = levels.split(',')
                setProductLevels(levels)
                setFetched(true)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    React.useEffect(() => {
        getSubscriptions()
        fetchData()
        loadRelated = true
        setPdfWidth(elementRef.current ? elementRef.current.offsetWidth : 0)
    }, [])

    const ref = React.createRef()

    const relatedProducts = (category) => {
        return <RelatedProduct category={category} />
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (rate) => {
        setAnchorEl(null);
        setPlaybackRate(rate)
    };

    const handleFullscreen = () => {
        setSmallScreen(false)
        // screenfull.request(findDOMNode(ref.current))
    }

    const handleDuration = (duration) => {

        // Starting Time In Seconds
        var timeStartHours = new Date("01/01/2021 " + previewStarts).getHours() * 60 * 60;
        var timeStartMinutes = new Date("01/01/2021 " + previewStarts).getMinutes() * 60;
        var timeStartSecs = new Date("01/01/2021 " + previewStarts).getSeconds();
        var previewStartTimeInSeconds = timeStartHours + timeStartMinutes + timeStartSecs
        // Ending Time In Seconds
        var timeEndHours = new Date("01/01/2021 " + previewEnds).getHours() * 60 * 60;
        var timeEndMinutes = new Date("01/01/2021 " + previewEnds).getMinutes() * 60;
        var timeEndSecs = new Date("01/01/2021 " + previewEnds).getSeconds();
        var previewEndTimeInSeconds = timeEndHours + timeEndMinutes + timeEndSecs
        // Total Preview Duration
        var previewTimeInSeconds = (timeEndHours + timeEndMinutes + timeEndSecs) - (timeStartHours + timeStartMinutes + timeStartSecs)

        ref.current.seekTo(timeStartHours + timeStartMinutes + timeStartSecs)
        setStartTime(previewStartTimeInSeconds)
        setTotalSeek(previewTimeInSeconds)
        setSeekTime('00:00:00')

        if (duration < previewTimeInSeconds)
            var time = new Date(duration * 1000).toISOString().substr(11, 8)
        else
            var time = new Date(previewTimeInSeconds * 1000).toISOString().substr(11, 8)
        setBuffering(true)
        setDuration(time)
    }

    const handleProgress = state => {
        var time = new Date(state.playedSeconds * 1000).toISOString().substr(11, 8)
        if (videoPlaying && playingVideo) {
            setSeek(seek + 1)
            var playedTime = new Date((seek + 1) * 1000).toISOString().substr(11, 8)
            setSeekTime(playedTime)
        }

        var totalPreview = new Date(totalSeek * 1000).toISOString().substr(11, 8)
        if (time === previewEnds || time > previewEnds) {
            playVideo(false)
            setPlayAgain(true)
            setSeek(totalSeek)
            setSeekTime(playDuration)
        }
    }

    // const handleDuration = (duration) => {
    //     setTotalSeek(duration)
    //     var time = new Date(duration * 1000).toISOString().substr(11, 8)
    //     setDuration(time)
    // }

    // const handleProgress = state => {
    //     setSeek(state.playedSeconds)
    //     var time = new Date(state.playedSeconds * 1000).toISOString().substr(11, 8)
    //     setSeekTime(time)
    //     // here
    //     if (playDuration == time) {
    //         playVideo(false)
    //         setPlayAgain(true)
    //         // setSeek(0)
    //     }
    // }


    const handleVolumeChange = (event, newValue) => {
        setVolume(newValue)
    }

    const handleSeekChange = (event, newValue) => {
        // console.log(newValue)
        // Starting Time In Seconds
        var timeStartHours = new Date("01/01/2021 " + previewStarts).getHours() * 60 * 60;
        var timeStartMinutes = new Date("01/01/2021 " + previewStarts).getMinutes() * 60;
        var timeStartSecs = new Date("01/01/2021 " + previewStarts).getSeconds();
        var previewMoveTimeInSeconds = timeStartHours + timeStartMinutes + timeStartSecs + newValue

        ref.current.seekTo(Math.floor(previewMoveTimeInSeconds))
        setSeek(Math.floor(newValue))
        if (playAgain)
            setPlayAgain(false)

        // ref.current.seekTo(newValue)
    }

    const handlePlayPauseVideo = () => {
        // setSeek(0)
        playVideo(playingVideo ? false : true)
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        // setTimeout(() => {
        //     const canvas = document.getElementsByClassName('react-pdf__Page__canvas');
        //     const ctx = canvas.canvas.getContext('2d')
        //     ctx.filter = 'blur(5px)';
        //     ctx.drawImage(canvas.canvas, 0, 0);
        // }, 300)
    }

    const handleSubcription = (subscriber_id, type) => {
        // console.log(subscriber_id)
        setSubscription(prevSub => [...prevSub, subscriber_id])
        var data = {
            'user_id': localStorage.getItem('user_id'),
            'subscriber_id': subscriber_id,
            'type': type
        }
        SubscriptionService.subscribeToCenter(data).then(res => {
            console.log(res)
        })
        // console.log(subscriptions)
    }

    const handleAuth = () => {
        setOpen(true)
    }

    const removeSubcription = (subscriber_id) => {
        setSubscription(subscriptions.filter(subscription => subscription !== subscriber_id))
        var data = {
            'user_id': localStorage.getItem('user_id'),
            'subscriber_id': subscriber_id
        }
        SubscriptionService.deleteSubscription(data).then(res => {
            console.log(res)
        })
    }

    const addToCart = (product) => {
        setCartItem(prevItems => [...prevItems, product])
        // console.log(product)
    }

    const removeFromCart = (course_id) => {
        setCartItem(cartItem.filter(item => item.course_id !== course_id))
    }

    const handleCheckoutClose = () => {
        setCheckoutOpen(false);
    };

    const handleCheckoutOpen = () => {
        setCheckoutOpen(true);
    };

    const handleConfirmation = () => {
        setCheckoutOpen(false)
        // setConfirmDialog(true);
        history.push({
            pathname: "/my-edhub",
            state: {
                image: localStorage.getItem('productImage'),
                id: product.course_id
            }
        })
        // history.push({
        //     pathname: "/product/view/" + product.course_name + '/' + product.course_id,
        //     state: {
        //         image: localStorage.getItem('productImage'),
        //         id: product.course_id
        //     }
        // })
        // return <Redirect to={{
        //     pathname: "/product/view/" + product.course_name,
        //     state: {
        //         image: product.course_cover,
        //         id: product.course_id
        //     }
        // }} />
    };

    const handleFastForward = () => {
        let newSeek = seek + 10;
        if (newSeek > playDuration)
            newSeek = playDuration
        ref.current.seekTo(newSeek)
    }

    const handleFastRewind = () => {
        let newSeek = seek - 10;
        if (newSeek < 0)
            newSeek = 0
        ref.current.seekTo(newSeek)
    }

    function existInCart(product) {
        if (cartItem != null) {
            return cartItem.some(function (el) {
                return el.course_id === product;
            });
        }
        return false
    }

    function ConfirmDialog() {
        return (
            <Dialog
                open={confirmDialog}
                maxWidth={'xs'}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <img src="/assets/check.png" className={classes.checkIcon} />
                </div>
                <DialogTitle className={classes.textCenter} id="alert-dialog-slide-title">
                    <span>Payment Processed</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.textCenter} id="alert-dialog-slide-description">
                        Thank You for your purchase.
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ marginBottom: 20 }}>
                    <Grid container layout="flex" justifyContent="center">
                        <Link onClick={handleClose} className={classes.okBtn} color="primary" style={{ textDecoration: 'none' }} to={{
                            pathname: "/product/view/" + product.course_name,
                            state: {
                                image: localStorage.getItem('productImage'),
                                id: product.course_id
                            }
                        }}>
                            View My Product
                        </Link>
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }

    function CheckoutDialog() {
        var lineItems = [
            {
                label: product.course_name,
                amount: parseFloat(product.course_tution_fee) * 100
            }
        ]
        return (
            <Dialog onBackdropClick={handleCheckoutClose} open={checkoutOpen} fullWidth={true} maxWidth={'sm'} onClose={handleCheckoutClose} TransitionComponent={Transition}>
                <ButtonBase onClick={handleCheckoutClose} className={classes.closeIcon}>
                    <CancelOutlinedIcon ></CancelOutlinedIcon>
                </ButtonBase>
                <Grid className={classes.authContainer} container direction="column" alignItems="center" justifyContent="center">
                    <h3 style={{ color: 'white', marginTop: 0 }}>Buy Now</h3>
                    <Grid className={classes.authWrapper} item xs={12} sm={10} lg={10}>
                        {!isExpired ?
                            <>
                                <Checkout courseTeacher={product.course_teacher} stripeConnect={product.stripe_connect_id} productId={product.course_id} confirmDialog={handleConfirmation} total={product.course_tution_fee} />
                                <p style={{ textAlign: 'center', color: '#c1c1c1', marginTop: 10, marginBottom: 10, fontSize: 14 }}>Or pay with</p>
                                <Wallet courseTeacher={product.course_teacher} stripeConnect={product.stripe_connect_id} lineItems={lineItems} confirmDialog={handleConfirmation} total={parseFloat(product.course_tution_fee) * 100} />
                            </>
                            :
                            <AuthForm />
                        }

                    </Grid>
                </Grid>
            </Dialog>
        )
    }

    const PdfNav = (props) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                <Tooltip title="First Page" placement="top">
                    <ButtonBase className={classes.navButtons} onClick={() => firstPage()}>
                        <Icon>skip_previous</Icon>
                    </ButtonBase>
                </Tooltip>
                <Tooltip title="Previous Page" placement="top">
                    <ButtonBase className={classes.navButtons} onClick={() => prevPage()}>
                        <Icon>chevron_left</Icon>
                    </ButtonBase>
                </Tooltip>
                <p style={{ color: 'white', marginLeft: 5, marginRight: 5 }}>Page {pageNumber} of {numPages}</p>
                <Tooltip title="Next Page" placement="top">
                    <ButtonBase className={classes.navButtons} onClick={props.nav ? nextPage : console.log('no')}>
                        <Icon>chevron_right</Icon>
                    </ButtonBase>
                </Tooltip>
                <Tooltip title="Last Page" placement="top">
                    <ButtonBase className={classes.navButtons} onClick={props.nav ? lastPage : console.log('no')}>
                        <Icon>skip_next</Icon>
                    </ButtonBase>
                </Tooltip>
            </div>
        )
    }

    let videoLessonCount = 0;
    function countIncrement() {
        videoLessonCount += 1;
    }

    let videoMaterial = [];
    let pdfMaterial = [];

    if (product.materials != null && product.materials.length > 0) {
        videoMaterial = product.materials.filter(item => {
            return (item.type == '.mp4' || item.type == 'mp4')
        })
        pdfMaterial = product.materials.filter(item => {
            return (item.type == '.pdf' || item.type == 'pdf')
        })
    }

    const VideosTab = (props) => {
        let materials = product.materials != null ? product.materials.filter(item => { return (item.type == '.mp4' || item.type == 'mp4') }) : null
        return (
            <TabPanel className={classes.tabWrapper} value={value} index={props.index} dir={theme.direction}>
                <Grid container className={classes.materialsInner}>
                    <Grid item xs={12}>
                        <List component="nav" aria-label="main mailbox folders">
                            {

                                materials != null &&
                                materials.map((material, index) => (
                                    (material.type == '.mp4' || material.type == 'mp4') &&
                                    <>

                                        {
                                            index < 1 ?
                                                // Set Video URL
                                                <ListItem key={material.id} onClick={() => {
                                                    setStart(material.preview_starts); setEnd(material.preview_ends);
                                                    if (material.subscriber_id == 0) {
                                                        // setVideo('material/' + material.material_name);
                                                        setVideo(material.material_name);
                                                    }
                                                    else {
                                                        setVideo('courses/materials/' + material.subscriber_id + '/' + material.material_name);
                                                    }
                                                    setPlayingMaterial(material.id)
                                                    setView('VIDEO');
                                                }} className={classes.item} button>
                                                    <ListItemIcon>
                                                        <Icon className={classes.playIcon}>play_circle</Icon>
                                                    </ListItemIcon>
                                                    <ListItemText className={classes.materialTitle} primary={material.org_name} />
                                                    {
                                                        (view == 'VIDEO' && playingMaterial == material.id) &&
                                                        <Icon className={classes.visibleIcon}>visibility</Icon>
                                                    }
                                                </ListItem>
                                                :
                                                <Tooltip title="Purchase the product to have full access" placement="top">
                                                    <ListItem key={material.id} className={classes.item} button>
                                                        <ListItemIcon>
                                                            <Icon className={classes.lockIcon}>lock</Icon>
                                                        </ListItemIcon>
                                                        <ListItemText className={classes.materialTitle} primary={material.org_name} />
                                                    </ListItem>
                                                </Tooltip>

                                        }
                                    </>
                                ))
                            }
                        </List>
                    </Grid>
                </Grid>
            </TabPanel>
        )
    }

    const WorksheetsTab = (props) => {
        return (
            <TabPanel className={classes.tabWrapper} value={value} index={props.index} dir={theme.direction}>
                <Grid container className={classes.materialsInner}>
                    <Grid item xs={12}>
                        <List component="nav" aria-label="main mailbox folders">
                            {
                                product.materials != null &&
                                product.materials.map((material, index) => (
                                    (material.type == '.pdf' || material.type == 'pdf') ?
                                        <ListItem key={material.id} onClick={() => {
                                            (
                                                material.subscriber_id == 0 ?
                                                    material.uploaded_channel == 'local' ?
                                                        setPdf(process.env.REACT_APP_FOLDER_URL + process.env.REACT_APP_MATERIAL_PATH + 'material/' + material.material_name)
                                                        :
                                                        setPdf('https://kountex-bucket.s3.amazonaws.com/' + material.material_name)
                                                    :
                                                    setPdf(process.env.REACT_APP_UPLOADS_URL + 'courses/materials/' + material.subscriber_id + '/' + material.material_name)
                                            );
                                            setNumPages(null);
                                            setView('PDF')
                                            setPlayingMaterial(material.id)
                                        }} className={classes.item} button>
                                            <ListItemIcon>
                                                <Icon className={classes.playIcon}>description</Icon>
                                            </ListItemIcon>
                                            <ListItemText className={classes.materialTitle} primary={material.org_name} />
                                            {
                                                (view == 'PDF' && playingMaterial == material.id) &&
                                                <Icon className={classes.visibleIcon}>visibility</Icon>
                                            }
                                        </ListItem>
                                        :
                                        ''
                                ))
                            }
                        </List>
                    </Grid>
                </Grid>
            </TabPanel>
        )
    }

    const handlePlayAgain = () => {
        // this one
        // Starting Time In Seconds
        var timeStartHours = new Date("01/01/2021 " + previewStarts).getHours() * 60 * 60;
        var timeStartMinutes = new Date("01/01/2021 " + previewStarts).getMinutes() * 60;
        var timeStartSecs = new Date("01/01/2021 " + previewStarts).getSeconds();
        var previewMoveTimeInSeconds = timeStartHours + timeStartMinutes + timeStartSecs

        ref.current.seekTo(Math.floor(previewMoveTimeInSeconds))
        setSeek(0)
        setSeekTime('00:00:00')
        setPlayAgain(false)
        handlePlayPauseVideo()
    }

    const CentreDetails = (props) => {
        let product = props.product
        return (<>
            <Link to={'/teachers-and-tutors/' + props.product.teacher_id}>
                <div className={classes.centreInfoWrapper}>
                    <img src={product.avatar} className={classes.avatar} />
                    {/* <span className={classes.avatar}>{product.centre_name[0]}</span> */}
                    <div className={classes.creditsWrapper}>
                        <small className={classes.credits}>Course By</small>
                        <span className={classes.centreName}>{product.centre_name}</span>
                    </div>
                </div>
            </Link>
            
            <div>
                <Grid container alignItems="center">
                    <Grid item style={{ marginRight: 10 }}>
                        <Link to={'/shop/' + props.product.username}>
                            <Button style={{ backgroundColor: '#1A95AC', color: 'white' }}>View more courses</Button>
                        </Link>
                    </Grid>
                    {
                        classesAvailable &&
                        <Grid item style={{ marginRight: 10 }}>
                            {
                                !isExpired ?
                                    <Link to={'/classes/' + product.course_name + '/' + product.course_id} style={{ textDecoration: 'none' }}>
                                        <Button style={{ backgroundColor: '#1A95AC', color: 'white' }}><Icon style={{ marginRight: 5, fontSize: 18 }}>schedule</Icon>View Class Schedule</Button>
                                    </Link>
                                    :
                                    <Button onClick={handleAuth} style={{ backgroundColor: '#1A95AC', color: 'white' }}><Icon style={{ marginRight: 5, fontSize: 18 }}>schedule</Icon>View Class Schedule</Button>
                            }
                        </Grid>
                    }
                    
                    <Grid item style={{ marginRight: 10 }}>

                        {!isExpired ?
                            subscriptions.indexOf(product.subscriber_id) > 0 ?
                                <Button onClick={() => removeSubcription(product.subscriber_id)} className={classes.unSubscribeBtn}>
                                    <Icon className={classes.bellIcon}>notifications_deactive</Icon>
                                    <Hidden xsDown>
                                        Unfollow
                                    </Hidden>
                                </Button>
                                :
                                <Button onClick={() => handleSubcription(product.subscriber_id, product.subscriber_type)} className={classes.subscribeBtn}>
                                    <Icon className={classes.bellIcon}>notifications</Icon>
                                    <Hidden xsDown>
                                        FOLLOW
                                    </Hidden>
                                </Button>
                            :
                            <Button onClick={() => handleAuth()} className={classes.subscribeBtn}>
                                <Icon className={classes.bellIcon}>notifications</Icon>
                                <Hidden xsDown>Sign In to Follow</Hidden>
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        <SocialIcons url={window.location.href} quote={product.course_name} title={product.course_name} />
                    </Grid>
                </Grid>
            </div>
        </>)
    }
    const TeacherDetails = (props) => {
        let product = props.product
        return (<>
            <div className={classes.centreInfoWrapper}>
                {/* <span className={classes.avatar}>{product.display_name != null && product.display_name[0]}</span> */}
                <img src={product.avatar != null && product.avatar} className={classes.avatar} />
                <div className={classes.creditsWrapper}>
                    <small className={classes.credits}>Course By</small>
                    <span className={classes.centreName}>{product.display_name}</span>
                </div>
            </div>
            <Grid item style={{ marginRight: 10 }}>
                <Link to={'/classes/' + product.course_name + '/' + product.course_id} style={{ textDecoration: 'none' }}>
                    <Button style={{ backgroundColor: '#1A95AC', color: 'white' }}><Icon>schedule</Icon>View class dates & time</Button>
                </Link>
            </Grid>
            {!isExpired ?
                subscriptions.indexOf(product.subscriber_id) > 0 ?
                    <Button onClick={() => removeSubcription(product.subscriber_id)} className={classes.unSubscribeBtn}>
                        <Icon className={classes.bellIcon}>notifications_deactive</Icon>
                        <Hidden xsDown>
                            Unfollow
                        </Hidden>
                    </Button>
                    :
                    <Button onClick={() => handleSubcription(product.course_teacher, product.subscriber_type)} className={classes.subscribeBtn}>
                        <Icon className={classes.bellIcon}>notifications</Icon>
                        <Hidden xsDown>
                            FOLLOW
                        </Hidden>
                    </Button>
                :
                <Button onClick={() => handleAuth()} className={classes.subscribeBtn}>
                    <Icon className={classes.bellIcon}>notifications</Icon>
                    <Hidden xsDown>Sign In to Follow</Hidden>
                </Button>
            }
        </>)
    }

    const SocialIcons = (props) => {
        return (
            <Grid container>
                <Grid item style={{display:'flex'}}>
                    <Tooltip title={"Share on Facebook"} placement="top">
                        <FacebookShareButton style={{display:'flex'}} url={props.url} quote={props.quote}>
                            <FacebookIcon size={38} />
                        </FacebookShareButton>
                    </Tooltip>
                    <Tooltip title={"Share on Whatsapp"} placement="top">
                        <WhatsappShareButton style={{display:'flex'}} url={props.url} title={props.tile} >
                            <WhatsappIcon size={38} />
                        </WhatsappShareButton>
                    </Tooltip>
                    <Tooltip title={"Share on Telegram"} placement="top">
                        <TelegramShareButton style={{display:'flex'}} url={props.url} title={props.title} >
                            <TelegramIcon size={38} />
                        </TelegramShareButton>
                    </Tooltip>
                </Grid>
            </Grid>
        )
    }

    const ProductNotFound = () => {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ height: '80vh', padding: 20 }}>
                <Grid item xs={12}>
                    <h1 style={{ fontSize: 100, color: '#f69a24', marginBottom: 10, marginTop: 0, textAlign: 'center' }}>Oops!</h1>
                    <Typography style={{ color: 'gray' }} variant="h4" align="center">Product not found</Typography>
                    <Typography style={{ color: 'gray' }} variant="body1" align="center">Product does not exist or might have been deleted.</Typography>
                </Grid>
            </Grid>
        )
    }

    return (
        <div className={classes.container}>
            {
                product.length != 0 &&
                    product.course_status != 1 ?
                    <ProductNotFound />
                    :
                    <>
                        <ConfirmDialog />
                        <CheckoutDialog />
                        <Grid className={classes.searchWrapper} container alignItems="stretch">
                            {
                                location.state &&
                                <Grid item xs={12}>
                                    <ButtonBase className={classes.backButton}>
                                        {/* <Link className={classes.backLink} to={'/search/' + location.state.from}><Icon className={classes.backIcon}>arrow_back</Icon> Back</Link> */}
                                        <div className={classes.backLink} onClick={() => history.goBack()}><Icon className={classes.backIcon}>arrow_back</Icon> Back</div>
                                    </ButtonBase>
                                </Grid>
                            }
                            <Grid container style={{ minHeight: '90vh' }}>
                                <Grid item xs={12} sm={12} lg={9} md={8}>
                                    <Hidden smUp>
                                        <Grid className={classes.titleWrapper}>
                                            <Typography variant="h5" components="h5" className={classes.heading}>
                                                {product.course_name}
                                            </Typography>
                                        </Grid>
                                    </Hidden>
                                    <Grid container className={classes.imageHolder}>
                                        {
                                            view != null
                                                ?
                                                view === 'VIDEO' ?
                                                    <>
                                                        {
                                                            !isBuffering &&
                                                            <Box className={classes.animatedBtnBox}>
                                                                {/* <SlideInDiv> */}
                                                                <ButtonBase onClick={() => handleCheckoutOpen(product)} className={classes.animatedBtn}><Icon style={{ marginRight: 5 }}>play_circle</Icon>Play full video</ButtonBase>
                                                                {/* </SlideInDiv> */}
                                                            </Box>
                                                        }
                                                        <div style={{ width: '100%' }} onClick={e => { setControls(0) }} onMouseEnter={e => { setControls(0) }} onMouseLeave={e => { setControls(0) }}>

                                                            {
                                                                isBuffering &&
                                                                <Grid className={classes.loader}>
                                                                    <img src="/assets/ringloader.gif" width="110" height="110" />
                                                                    <Typography variant="h5" style={{ marginLeft: 10, color: 'white' }}>Loading...</Typography>
                                                                </Grid>
                                                            }
                                                            <Hidden xsUp>
                                                                <Grid container className={classes.videoControls} style={{ bottom: controlsPosition }}>

                                                                    <Grid item xs={12}>
                                                                        <PrettoSlider className={classes.seekBar} min={0} step={0.1} max={totalSeek} value={seek}
                                                                            // <PrettoSlider className={classes.seekBar} min={0} max={totalSeek} value={0}
                                                                            // onMouseDown={this.handleSeekMouseDown}
                                                                            onChange={handleSeekChange}
                                                                        // onMouseUp={this.handleSeekMouseUp}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={9} className={classes.leftControls}>
                                                                        <div className={classes.playerControl}>
                                                                            {
                                                                                playingVideo ?
                                                                                    <Tooltip title="Pause" placement="top">
                                                                                        <ButtonBase onClick={handlePlayPauseVideo}>
                                                                                            <Icon className={classes.playBtn}>pause</Icon>
                                                                                        </ButtonBase>
                                                                                    </Tooltip>
                                                                                    :
                                                                                    playAgain ?
                                                                                        <Tooltip title="Play Again" placement="top">
                                                                                            <ButtonBase onClick={handlePlayAgain}>
                                                                                                <Icon className={classes.playBtn}>replay</Icon>
                                                                                            </ButtonBase>
                                                                                        </Tooltip>
                                                                                        :
                                                                                        <Tooltip title="Play" placement="top">
                                                                                            <ButtonBase onClick={handlePlayPauseVideo}>
                                                                                                <Icon className={classes.playBtn}>play_arrow</Icon>
                                                                                            </ButtonBase>
                                                                                        </Tooltip>
                                                                            }

                                                                        </div>
                                                                        <Hidden xsUp>
                                                                            <div className={classes.playerControl}>
                                                                                <Tooltip title="10-seconds Rewind" placement="top">
                                                                                    <ButtonBase onClick={() => handleFastRewind()}>
                                                                                        <Icon className={classes.speedIcon}>history</Icon>
                                                                                    </ButtonBase>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div className={classes.playerControl}>
                                                                                <Tooltip title="10-seconds Forward" placement="top">
                                                                                    <ButtonBase onClick={() => handleFastForward()}>
                                                                                        <Icon className={classes.speedIcon}>update</Icon>
                                                                                    </ButtonBase>
                                                                                </Tooltip>
                                                                            </div>
                                                                            <div className={classes.playerControl}>
                                                                                <Tooltip title="Play Speed" placement="top">
                                                                                    <ButtonBase className={classes.speedIcon} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                                                                                        {playbackRate + 'x'}
                                                                                    </ButtonBase>
                                                                                </Tooltip>
                                                                                <Menu
                                                                                    id="simple-menu"
                                                                                    anchorEl={anchorEl}
                                                                                    keepMounted
                                                                                    open={Boolean(anchorEl)}
                                                                                    onClose={handleClose}
                                                                                >
                                                                                    <MenuItem onClick={() => handleClose(0.5)}>0.5x Slow</MenuItem>
                                                                                    <MenuItem onClick={() => handleClose(1)}>1x Normal</MenuItem>
                                                                                    <MenuItem onClick={() => handleClose(1.5)}>1.5x Fast</MenuItem>
                                                                                    <MenuItem onClick={() => handleClose(2)}>2x Faster</MenuItem>
                                                                                </Menu>

                                                                            </div>
                                                                        </Hidden>
                                                                        <div className={classes.playDuration}>
                                                                            {seekTime + ' / ' + playDuration}
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid item sm={3} xs={12} className={classes.rightControls}>
                                                                        <Grid container justifyContent="flex-end" alignItems="center">
                                                                            <Hidden mdDown>
                                                                                <div className={classes.volumeControl}>
                                                                                    {
                                                                                        volume > 0 ?
                                                                                            <ButtonBase>
                                                                                                {
                                                                                                    volume > 50 ?
                                                                                                        <Icon className={classes.iconControls}>volume_up</Icon>
                                                                                                        :
                                                                                                        <Icon className={classes.iconControls}>volume_down</Icon>
                                                                                                }
                                                                                            </ButtonBase>
                                                                                            :
                                                                                            <ButtonBase>
                                                                                                <Icon className={classes.iconControls}>volume_off</Icon>
                                                                                            </ButtonBase>
                                                                                    }

                                                                                    <div className={classes.volumeSlider}>
                                                                                        <VolumeSlider color={'red'} value={volume} onChange={handleVolumeChange} aria-labelledby="continuous-slider" />
                                                                                    </div>
                                                                                </div>
                                                                            </Hidden>
                                                                            {/* <div className={classes.fullScreenControl}>
                                                                    <Icon className={classes.fullScreen} onClick={() => handleFullscreen()}>fullscreen</Icon>
                                                                </div> */}
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Hidden>
                                                            <ReactPlayer
                                                                ref={ref}
                                                                playing={playingVideo}
                                                                controls={true}
                                                                playsinline={smallScreen}
                                                                playbackRate={playbackRate}
                                                                className='react-player'
                                                                url={
                                                                    // process.env.REACT_APP_FOLDER_URL + process.env.REACT_APP_MATERIAL_PATH + videoUrl
                                                                    product.subscriber_type == 'teacher' ?
                                                                        videoUrl
                                                                        :
                                                                        process.env.REACT_APP_UPLOADS_URL + videoUrl
                                                                }
                                                                width='100%'
                                                                volume={volume / 100}
                                                                height='auto'
                                                                onBuffer={() => setBuffering(true)}
                                                                onBufferEnd={() => setBuffering(false)}
                                                                onReady={() => setBuffering(false)}
                                                                onDuration={handleDuration}
                                                                onProgress={handleProgress}
                                                                onSeek={() => { setVideoPlaying(true); console.log('Playing Now') }}
                                                            />
                                                        </div>
                                                    </>
                                                    :
                                                    <div ref={elementRef} className={product.pdf_preview_mode == 'high' ? 'pdfHigh' : 'pdfLow'} style={{ width: product.pdf_preview_mode == 'low' ? '100%' : '100%', display: 'flex', justifyContent: 'center', height: '75vh', overflowY: 'scroll' }}>
                                                        {
                                                            numPages == null &&
                                                            <div className={classes.pdfLoader}>
                                                                <img src="/assets/ringloader.gif" width="110" height="110" />
                                                            </div>
                                                        }

                                                        <Document
                                                            style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}
                                                            file={{ url: pdfUrl }}
                                                            onLoadSuccess={onDocumentLoadSuccess}
                                                            options={options}
                                                            width={800}
                                                            canvasRef="ddd"
                                                        >
                                                            {/* <Page width={pdfWidth} scale={product.pdf_preview_mode == 'high' ? 0.9 : 0.1} pageNumber={pageNumber} /> */}
                                                            <Page width={pdfWidth} pageNumber={pageNumber} />
                                                            {
                                                                product.pdf_preview_mode == 'high'
                                                                    ?
                                                                    <>
                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <Chip style={{ marginBottom: 20 }} label={'Showing ' + pageNumber + ' of ' + numPages + ' pages. Buy this product to access all pages!'} color="secondary" />
                                                                        </div>
                                                                        <PdfNav nav={product.pdf_preview_mode == 'high' ? false : true} />
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                                            <Chip style={{ marginBottom: 20 }} label={'Showing ' + pageNumber + ' of ' + numPages + ' pages. Buy this product to access all pages in high resolution!'} color="secondary" />
                                                                        </div>
                                                                        <PdfNav nav={product.pdf_preview_mode == 'high' ? false : true} />
                                                                    </>
                                                            }
                                                        </Document>

                                                    </div>
                                                :
                                                <ButtonBase onClick={() => playVideo(true)} className={classes.imageRipple}>

                                                    <img className={classes.imageWrapper} src={localStorage.getItem('productImage')} />
                                                </ButtonBase>
                                        }
                                    </Grid>
                                    <Hidden xsDown>
                                        <Grid className={classes.titleWrapper}>
                                            <Typography variant="h5" components="h5" className={classes.heading}>
                                                {product.course_name}
                                            </Typography>
                                        </Grid>
                                    </Hidden>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={3} md={4} className={classes.materialsWrapper}>
                                    <Grid style={{ marginBottom: 10 }} container alignItems="center" justifyContent="space-between">
                                        <Grid>
                                            {product.discounted_price != null && product.discounted_price != '' && product.discounted_price < product.course_tution_fee ?
                                                <span className={classes.discountedPrice}>${product.course_tution_fee}</span>
                                                :
                                                <span></span>
                                            }
                                            {
                                                (product.discounted_price != null && product.discounted_price != '') && product.discounted_price < product.course_tution_fee ?
                                                    <span className={classes.coursePrice}>${product.discounted_price} HK</span>
                                                    :
                                                    <span className={classes.coursePrice}>${product.course_tution_fee} HK</span>
                                            }
                                        </Grid>
                                        {
                                            !(usersProducts.includes(product.course_id)) &&
                                            <ButtonGroup color="primary" aria-label="outlined primary button group">
                                                {
                                                    existInCart(product.course_id)
                                                        ?
                                                        <Tooltip title="Remove from Cart" placement="top">
                                                            <IconButton className={classes.removeBtn} onClick={() => removeFromCart(product.course_id)} size="small" color="primary">
                                                                <Icon className={classes.icon}>clear</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                        :
                                                        <Tooltip title={"Add to Cart ($" + (product.discounted_price != null && product.discounted_price != '' ? product.discounted_price : product.course_tution_fee) + " HK)"} placement="top">
                                                            <IconButton className={classes.addBtn} onClick={() => addToCart(product)} size="small" color="primary">
                                                                <Icon className={classes.icon}>shopping_cart</Icon>
                                                            </IconButton>
                                                        </Tooltip>
                                                }
                                                <Tooltip title={"Buy Now ($" + (product.discounted_price != null && product.discounted_price != '' ? product.discounted_price : product.course_tution_fee) + " HK)"} placement="top">
                                                    <IconButton className={classes.buyBtn} onClick={() => handleCheckoutOpen(product)} size="small" color="primary">
                                                        Buy Now
                                                </IconButton>
                                                </Tooltip>
                                            </ButtonGroup>
                                        }

                                    </Grid>
                                    <AppBar style={{ borderTopLeftRadius: 15, borderTopRightRadius: 15 }} position="static" color="default">
                                        {videoMaterial.length > 0 ?
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                aria-label="full width tabs example"
                                            >
                                                <Tab className={classes.tab} label="Videos" {...a11yProps(0)} />
                                                <Tab className={classes.tab} label="Worksheets" {...a11yProps(1)} />
                                            </Tabs>

                                            // (videoMaterial.length == 0 || pdfMaterial.length > 0) &&
                                            :
                                            <Tabs
                                                value={value}
                                                onChange={handleChange}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                aria-label="full width tabs example"
                                            >
                                                <Tab label="Worksheets" {...a11yProps(0)} />
                                                <Tab label="Videos" {...a11yProps(1)} />
                                            </Tabs>
                                        }
                                    </AppBar>
                                    {
                                        (videoMaterial.length > 0 || pdfMaterial.length == 0) &&
                                        <SwipeableViews
                                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                            index={value}
                                            onChangeIndex={handleChangeIndex}
                                            className={classes.swipeableWrapper}
                                        >
                                            <VideosTab index={0} />
                                            <WorksheetsTab index={1} />
                                        </SwipeableViews>
                                    }
                                    {
                                        (videoMaterial.length == 0 && pdfMaterial.length > 0) &&
                                        <SwipeableViews
                                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                            index={value}
                                            onChangeIndex={handleChangeIndex}
                                            className={classes.swipeableWrapper}
                                        >
                                            <WorksheetsTab index={0} />
                                            <VideosTab index={1} />
                                        </SwipeableViews>
                                    }
                                </Grid>
                            </Grid>

                            <Grid item xs={12} lg={9} md={8} sm={12} className={classes.subscriberFooter}>
                                {
                                    product.centre_name != null ?
                                        <CentreDetails product={product} />
                                        :
                                        <TeacherDetails product={product} />

                                }

                            </Grid>
                        </Grid>
                        <Container maxWidth={"xl"}>
                            <Grid className={classes.aboutContainer} container>
                                <Grid className={classes.aboutWrapper} item xs={12} sm={7}>
                                    <Typography variant="h5" component="h4">About This Course</Typography>
                                    <div className={classes.description} >
                                        {/* <Typography variant="body1" >
                                {product != null && product.course_description}
                            </Typography> */}
                                        <div dangerouslySetInnerHTML={{
                                            __html: product != null && product.course_description
                                        }}></div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <Paper variant="outlined" elevation={3}>
                                        <div className={classes.stats}>
                                            <Grid container>
                                                <Grid item xs={6}>
                                                    <div className={classes.stasWrapper}>
                                                        <Icon className={classes.statIcon1}>stacked_bar_chart</Icon>
                                                        <span>{product.course_grade_level} Level</span>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <div className={classes.stasWrapper}>
                                                        <Icon className={classes.statIcon3}>attach_money</Icon>
                                                        <span>{product.course_tution_fee} HK</span>
                                                    </div>
                                                </Grid>
                                                <Grid style={{ marginTop: 20 }} item xs={12}>
                                                    <div className={classes.tagWrapper}>
                                                        <Icon style={{ marginRight: 10 }} className={classes.statIcon2}>school</Icon>
                                                        {
                                                            productLevels.map((level, index) => {
                                                                return (
                                                                    <span className={classes.levelTag} key={index}>{level}</span>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Paper>
                                    <Typography variant="h6" component="h6" className={classes.productTagHeading}>
                                        Course Tags
                        </Typography>
                                    <div className={classes.tagsWrapper}>
                                        {
                                            product.tags != null &&
                                            product.tags.split(',').map((tag, index) => (
                                                <Link to={'/search/' + tag} style={{ cursor: 'pointer', textDecoration: 'none' }}>
                                                    <Chip style={{ cursor: 'pointer' }} key={index} className={classes.tag} key={index} label={tag} />
                                                </Link>
                                            ))
                                        }
                                    </div>
                                </Grid>
                                <Grid xs={12} className={classes.commentsContainer}>
                                    <Typography variant="h5" style={{ marginTop: 0, marginBottom: 20 }} component="h4">Rating and Reviews</Typography>
                                    {
                                        fetched &&
                                        <Comments showForm={false} id={product.course_id} rating={product.rating} comments={product.comments} />
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {relatedProducts('Writing')}
                                </Grid>
                            </Grid>
                        </Container>
                    </>
            }
        </div >
    )
}

