import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import Image from 'material-ui-image';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Icon from '@material-ui/core/Icon';
import axios from 'axios';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { CartContext } from '../context/CartContext';
import { UserContext } from '../context/UserContext';
import SignIn from '../components/auth/SingleSignIn';
import { useJwt } from "react-jwt";
import PaymentService from '../services/payments';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Wallets from '../components/checkout/wallet';
import Checkout from '../components/checkout/checkout';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { PaymentRequestButtonElement, useStripe, useElements } from '@stripe/react-stripe-js';

import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";

const pics = [

    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
    "assets/class3.jpeg",
    "assets/class4.jpeg",
    "assets/class5.jpeg",
    "assets/class6.jpeg",
    "assets/class1.jpeg",
    "assets/class2.jpeg",
]

// const token = localStorage.getItem('jwt_token');

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 60,
        minHeight: '85vh'
    },
    cartWrapper: {
        backgroundColor: 'white',
        marginTop: 50,
        padding: 20,
        borderRadius: 15,
        [theme.breakpoints.down('sm')]: {
            padding: 10,
            marginRight: 10
        },
        [theme.breakpoints.down('xs')]: {
            padding: 10
        }
    },
    itemImageWrapper: {
        paddingRight: 20,
        height: 80,
        borderRadius: 10,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 5,
            borderRadius: 10,
        }
    },
    itemImage: {
        width: '100%',
        height: '100%',
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            borderRadius: 5
        }
    },
    itemContainer: {
        paddingTop: 30,
        paddingBottom: 0
    },

    removeIcon: {
        color: '#c74343',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            color: 'white',
            backgroundColor: '#c74343',
            borderRadius: 20,
            fontSize: 17
        }
    },
    singleItem: {
        marginBottom: 20,
        padding: 10,
        border: 'solid',
        borderWidth: 1,
        borderRadius: 5,
        position: 'relative',
        backgroundColor: "#f4f4f4",
        borderColor: '#c1c1c1',
        [theme.breakpoints.down('sm')]: {
            padding: 5,
            border: 'solid',
            borderWidth: 1,
            borderRadius: 5,
            borderColor: '#d2d2d2'
        }
    },
    mobileDelete: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute',
            top: -10,
            right: -8,
        }
    },
    lightBorder: {
        borderColor: 'white'
    },
    singleAmount: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between'
        }
    },
    singleValue: {
        paddingLeft: 30
    },
    cartAmount: {
        // marginBottom: 30,
    },
    cardWrapper: {
        marginTop: 50,
        padding: 20,
        backgroundColor: '#fff',
        marginLeft: 30,
        borderRadius: 15,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            padding: 10
        }
    },
    elementWrapper: {
        marginTop: 5,
        height: 40,
        disaply: 'flex',
        alignItems: 'center'
    },
    cardele: {
        backgroundColor: 'white',
        disaply: 'flex',
        alignItems: 'center',
        padding: 15,
        paddingLeft: 10,
        paddingRight: 10,
        borderRadius: 5,
        border: 'solid',
        borderWidth: 1,
        borderColor: '#cacaca'
    },
    cardMarginBottom: {
        marginBottom: 20
    },
    checkoutBtn: {
        marginTop: 40
    },
    fullBtn: {
        width: '100%'
    },
    paymentIcons: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 40
    },
    payWallet: {
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 20,
        color: 'gray'
    },
    textCenter: {
        textAlign: 'center'
    },
    dialogBody: {
        overflow: 'visible',
    },
    edhubBtn: {
        backgroundColor: '#efefef',
        color: 'black',
        marginRight: 10,
        '&:hover': {
            backgroundColor: '#d8d7d7'
        }
    },
    okBtn: {
        backgroundColor: '#55b96d',
        color: 'white',
        marginLeft: 10,
        '&:hover': {
            backgroundColor: '#47a25d'
        }
    },
    checkIcon: {
        width: '15%'
    },
    courseName: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            fontWeight: 300,
            marginBottom: 4,
        }
    },
    productPrice: {
        paddingRight: 8,
        fontSize: 18,
        paddingTop: 5
    }
}));
// StylingEnd
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Cart() {
    const [open, setOpen] = React.useState(false);
    const { jwt } = React.useContext(UserContext)
    const [token, setToken] = jwt;
    const { decodedToken, isExpired } = useJwt(token);
    const classes = useStyles();
    let subTotal = 0;
    let lineItems = [];
    const [cartItem, setCartItem] = useContext(CartContext);
    const [isProcessing, setProcessing] = React.useState(false)
    const [totalAmount, setTotalAmount] = React.useState(0);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const [cartSubTotal, setCartSubTotal] = React.useState(0)

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const clearCart = () => {
        setCartItem([])
    }

    React.useEffect(() => {
        console.log(cartItem)
    }, [])

    const items = [
        {
            id: 1,
            title: 'Product Title 1',
            image: '/assets/class1.jpeg',
            price: '50',
            description: 'Some Random Description'
        },
        {
            id: 2,
            title: 'Product Title 2',
            image: '/assets/class2.jpeg',
            price: '60',
            description: 'Some Random Description'
        },
        {
            id: 3,
            title: 'Product Title 3',
            image: '/assets/class3.jpeg',
            price: '20',
            description: 'Some Random Description'
        },
    ]

    const removeFromCart = (removeItem) => {
        setCartItem(cartItem.filter(item => item != removeItem))
    }


    function ConfirmDialog() {
        return (
            <Dialog
                open={open}
                maxWidth={'xs'}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <img src="/assets/check.png" className={classes.checkIcon} />
                </div>
                <DialogTitle className={classes.textCenter} id="alert-dialog-slide-title">
                    <span>Payment Processed</span>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.textCenter} id="alert-dialog-slide-description">
                        Thank You for your purchase. You can find your purchased products <Link to="my-edhub" style={{ color: '#3298bf' }}>here</Link>
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ marginBottom: 20 }}>
                    <Grid container layout="flex" justifyContent="center">
                        <Button onClick={handleClose} className={classes.okBtn} color="primary">
                            OK
                        </Button>
                    </Grid>
                </DialogActions>
            </Dialog>
        )
    }

    // My Account
    // const stripePromise = loadStripe('pk_test_51FJJDOBHbro2M23KW7NVdpcqTM5K1um0DoXkwbQBTBdfVqE79JYagiJ2zsjA7m7ueWhYEMxbNtAsD9SF808vLCVt00wkpg8mV6');

    // Test EdHub
    const stripePromise = loadStripe('pk_test_51IMXtPCOH41oJfZ81vTZWI569OstO74UFOXuhFfJDtkEtsx7i91OD1aFCZek953mYA0DOT549GDO1Hz8hfTtwYNe00eV4UIXuK');
    // Live EdHub
    // const stripePromise = loadStripe('pk_live_51IMXtPCOH41oJfZ8IhyIqjDs4ttNWcucoUpGcwYrmeviUcuc39ucZpz1gnpH0MXjgpOaakr6oqkgh2cnCOP9laJY00NxDvmlzw');

    return (
        <Container className={classes.root} maxWidth="lg" >
            <ConfirmDialog />
            <Grid container alignItems="flex-start" justifyContent="center">
                {!isExpired &&
                    <Grid item xs={12} sm={7} className={classes.cartWrapper}>
                        <Typography variant="h5">Your Cart Items</Typography>
                        {/* <hr></hr> */}
                        <Grid container className={classes.itemContainer}>
                            {
                                cartItem != null &&
                                cartItem.map((item, index) => {
                                    subTotal += parseFloat(item.discounted_price != null && item.discounted_price != '' ? item.discounted_price : item.course_tution_fee);
                                    lineItems.push(
                                        {
                                            label: item.course_name,
                                            amount: parseFloat(item.discounted_price != null && item.discounted_price != '' ? item.discounted_price : item.course_tution_fee) * 100
                                        }
                                    )
                                    return (
                                        <Grid key={index} item xs={12} className={classes.singleItem}>
                                            <Grid container flexdirection="row">
                                                <Grid item xs={5} sm={3} className={classes.itemImageWrapper}>
                                                    {
                                                        item.course_cover != null && item.course_cover != '' ?
                                                            (item.subscriber_id == 0 || (item.course_teacher != null && item.course_teacher != '')) ?
                                                                <>
                                                                    <img src={process.env.REACT_APP_API_URL + process.env.REACT_APP_TEACHER_COURSE_PATH + 'cover/' + item.course_cover} className={classes.itemImage} />
                                                                </>
                                                                :
                                                                <img src={process.env.REACT_APP_UPLOADS_URL + 'courses/covers/' + item.subscriber_id + '/' + item.course_cover} className={classes.itemImage} />
                                                            :
                                                            <img src={'/' + pics[index]} className={classes.itemImage} />
                                                    }
                                                </Grid>
                                                <Grid item xs={7} sm={9}>
                                                    <Grid container flexdirection="column">
                                                        <Grid item xs={12}>
                                                            <Grid container flexdirection="row" alignItems="center">
                                                                <Grid item xs={12} sm={8}>
                                                                    <Typography className={classes.courseName} variant="h6">
                                                                        {item.course_name.substring(0, 45)} {item.course_name.length > 45 && '....'}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} sm={3}>
                                                                    <Typography className={classes.productPrice} align="right" variant="h6">${item.discounted_price != null && item.discounted_price != '' ? item.discounted_price : item.course_tution_fee} HK</Typography>
                                                                </Grid>
                                                                <Grid className={classes.mobileDelete} item xs={2} sm={1}>
                                                                    <Hidden smDown>
                                                                        <Typography onClick={() => removeFromCart(item)} align="right" variant="subtitle2">
                                                                            <Icon className={classes.removeIcon}>delete_outline</Icon>
                                                                        </Typography>
                                                                    </Hidden>
                                                                    <Hidden mdUp>
                                                                        <Typography onClick={() => removeFromCart(item)} align="right" variant="subtitle2">
                                                                            <Icon className={classes.removeIcon}>clear</Icon>
                                                                        </Typography>
                                                                    </Hidden>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {/* <Hidden smDown>
                                                <hr className={classes.lightBorder}></hr>
                                            </Hidden> */}
                                        </Grid>
                                    )
                                })
                            }

                            <Grid item xs={12} className={classes.cartAmount}>
                                <div className={classes.singleAmount}>
                                    <span >Subtotal:</span>
                                    <span className={classes.singleValue}>${subTotal} HK</span>
                                </div>
                                <div className={classes.singleAmount}>
                                    <span >Estimated Tax:</span>
                                    <span className={classes.singleValue}>${0} HK</span>
                                </div>
                                <div className={classes.singleAmount}>
                                    <Typography variant="h6" align="right">Total:</Typography>
                                    <Typography variant="h6" align="right" className={classes.singleValue}>${subTotal} HK</Typography>
                                </div>


                            </Grid>
                        </Grid>
                    </Grid>
                }
                <Grid item xs={12} sm={isExpired ? 8 : 4} className={classes.cardWrapper}>
                    {
                        isExpired ?
                            <SignIn redirect={'none'} />
                            :
                            <div>

                                <Elements stripe={stripePromise}>
                                    <Checkout confirmDialog={handleClickOpen} total={subTotal} />
                                    {/* <p className={classes.payWallet}>Or pay with</p> */}
                                    <Wallets type="cart" lineItems={lineItems} confirmDialog={handleClickOpen} total={subTotal * 100} />
                                </Elements>
                            </div>
                    }
                </Grid>
            </Grid>
        </Container>
    );
}