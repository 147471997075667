import React from 'react'
import { InputBase, MenuItem, Grid, Icon, Typography, Button, IconButton } from '@material-ui/core'
import styled from "styled-components"
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import Tooltip from '@material-ui/core/Tooltip';
import EventAvailableOutlinedIcon from '@material-ui/icons/EventAvailableOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';
import { UserContext } from '../../context/UserContext'
import Classes from '../../services/classes'
import { Payment } from '../payment';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import { CircularProgress } from '@material-ui/core';
import { useIsLink } from '../../hooks'


const ColorCircle = styled.span`
    width:22px;
    height:22px;
    border-radius:20px;
    margin-right:10px;
    background-color:${props => props.color}
`;

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export default function EnrolmentForm({ handeEditCourse, edit, teacher, handleClossSidebar, handleEnrolment, event, handleAfterEnrolment }) {

    const [startDate, setStartDate] = React.useState(new Date())
    const [endDate, setEndDate] = React.useState(new Date())
    const [submitting, setSubmitting] = React.useState(false)
    const [limitExceed, setLimitExceed] = React.useState(false)
    const [paymentPopUp, setPaymentPopUp] = React.useState(false)
    const [limitPopUp, setLimitPopUp] = React.useState(false)
    const [materials, setMaterials] = React.useState([])
    const [venue, setVenue] = React.useState()
    const [alreadyRegistered, setAlreadyRegistered] = React.useState(false)
    const [downloading, setDownloading] = React.useState(false)
    const { user } = React.useContext(UserContext)
    const isLink = useIsLink(event._def.extendedProps?.venue)

    React.useEffect(() => {
        console.log(event)
        let url = event._def.extendedProps?.venue
        if (url.indexOf('https://') == 0 || url.indexOf('http://') == 0) {
            setVenue(url)
        } else {
            setVenue('https://' + url)
        }

        setStartDate(event?.start)
        if (event != null) {
            let data = {
                'class_id': event._def.extendedProps?.class_id,
                'student_id': user[0].user_id
            }

            Classes.getEnrolment(data).then(res => {
                console.log(res.data)
                setMaterials(res.data.materials)
                if (res.data.enrolment.length > 0) {
                    setAlreadyRegistered(true)
                }
                else {
                    setAlreadyRegistered(false)
                }

                // alert(event._def.extendedProps?.students_limit)
                if (res.data.enrolledStudents >= event._def.extendedProps?.students_limit && res.data.enrolledStudents != 0) {
                    setLimitExceed(true)
                }
                else {
                    setLimitExceed(false)
                }
            })
        }
    }, [event])

    function getFormatedDate(date) {
        console.log(date)
        return monthNames[date.getMonth()] + ' ' + date.getDate() + ' ' + date.getFullYear()
    }

    function getDayName(date, locale) {
        if (typeof (date) != 'undefined') {
            var date = new Date(date);
            return date.toLocaleDateString(locale, { weekday: 'short' });
        }
    }

    function getFormattedTime(time) {
        if (typeof (time) != 'undefined') {
            time = time.split(':')
            let hour = (parseInt(time[0]) - 12)
            if (time[0] > 12) {
                if (hour < 10)
                    return ("0" + hour + ':' + time[1] + 'pm')
                else
                    return (hour + ':' + time[1] + 'pm')
            }
            else {
                return time[0] + ':' + time[1] + 'am'
            }
        }
    }
    const serverUrl = process.env.REACT_APP_UPLOADS_URL;
    const download = (fileUrl, filename, subscriberId) => {
        setDownloading(true)
        var req = new XMLHttpRequest();
        // req.open("GET", process.env.REACT_APP_FOLDER_URL + process.env.REACT_APP_MATERIAL_PATH + fileUrl, true);
        if (subscriberId == 0)
            req.open("GET", 'https://kountex-bucket.s3.amazonaws.com/' + fileUrl, true);
        else
            req.open("GET", serverUrl + fileUrl, true);

        req.responseType = "blob";
        req.onload = function (event) {
            var blob = req.response;
            // console.log(blob.size);
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = filename;
            link.click();
            setDownloading(false)
        };

        req.send();
    }

    const enroll = () => {
        let data = {
            'classes': [event._def.extendedProps?.class_id],
            'student_id': user[0].user_id
        }

        Classes.enroll(data).then(res => {
            console.log(res.data)
            if (res.data.status == 'success') {
                setPaymentPopUp(false)
                handleClossSidebar()
                handleAfterEnrolment(limitPopUp ? 'Request sent!' : 'Successfuly enrolled!')
                setLimitPopUp(false)
            }
            setSubmitting(false)
        }, err => {

        })
    }

    const handleConfirmation = () => {
        enroll()
    }

    const MakePayment = ({ total, title }) => {
        return (
            <Dialog maxWidth="xs" open={paymentPopUp} >
                <Grid style={{ padding: 20 }}>
                    <Button onClick={() => setPaymentPopUp(false)} style={{ position: "absolute", right: 5, top: 5 }}>
                        X
                    </Button>
                    <Grid container direction="column" alignItems="center" justifyContent="center">
                        <img src="/assets/Logo.png" style={{ width: '35%', marginBottom: 10 }} />
                        <h3 style={{ color: 'black', marginTop: 0, marginBottom: 20 }}>Enroll Now</h3>
                        <Payment handleConfirmation={handleConfirmation} total={total} itemName={title} />
                    </Grid>
                </Grid>
            </Dialog>
        )
    }

    const LimitExceedPopUp = ({ total, title }) => {
        return (
            <Dialog maxWidth="xs" fullWidth={true} open={limitPopUp} >
                <Grid style={{ padding: 20 }}>
                    <IconButton size="small" onClick={() => setLimitPopUp(false)} style={{ position: "absolute", right: 5, top: 5 }}>
                        <CloseIcon />
                    </IconButton>
                    <Grid container direction="column" alignItems="center" justifyContent="center">
                        <Typography variant="h5" style={{ color: 'black', marginTop: 0, marginBottom: 20 }}>No space available</Typography>
                        <Typography align="center" variant="body1" style={{ color: 'black', marginTop: 20, marginBottom: 20 }}>Sorry the class is full already. We will notify the teacher and see what we can do for you. Thank you!</Typography>
                        {
                            submitting ?
                                <Button style={{ marginTop: 10, backgroundColor: '#1A95AC', color: 'white' }}>Requesting...</Button>
                                :
                                <Button onClick={() => { handleConfirmation(); setSubmitting(true) }} style={{ marginTop: 10, backgroundColor: '#1A95AC', color: 'white' }}>Request for enrollment</Button>
                        }
                    </Grid>
                </Grid>
            </Dialog>
        )
    }

    return (
        <Grid className="right-sidebar-scroller" style={{ height: "87vh", overflowY: 'scroll', overflowX: 'hide', backgroundColor: '#E9E9E9', border: 'solid', borderColor: '#CFCFCF', borderWidth: 1, padding: 10, borderRadius: 10 }} item xs={12} lg={3}>

            <Grid container justifyContent="space-between" alignItems="center" style={{ marginBottom: 20 }}>
                <Typography align="left" variant="h6">Enroll now</Typography>
                <Icon onClick={handleClossSidebar} style={{ cursor: 'pointer' }}>close</Icon>
            </Grid>
            <Grid container direction="column" justifyContent="space-between" style={{ height: '91%' }}>
                <Grid item>
                    {
                        event != null &&
                        <>
                            <Grid container alignItems="center">
                                <Typography style={{ background: '#dddddd', padding: 5, borderRadius: 5, flex: 1, borderLeft: 'solid', borderWidth: 8, borderColor: event.backgroundColor, paddingLeft: 10 }} variant="h6">{event._def?.title}</Typography>
                            </Grid>
                            <Grid container alignItems="center">
                                <Typography style={{ marginTop: 10 }} variant="h6">$HKD {event._def.extendedProps?.class_price}</Typography>
                            </Grid>
                            <Grid container alignItems="center" style={{ marginTop: 20, marginBottom: 15 }}>
                                <Grid item>
                                    <EventAvailableOutlinedIcon style={{ marginRight: 10 }} />
                                </Grid>
                                <Grid item style={{ flex: 1 }}>
                                    <Grid container justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="body1">{getDayName(event?.start, 'us-EN')}, {getFormatedDate(event?.start)}  </Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container alignItems="center" style={{ marginTop: 10, marginBottom: 15 }}>
                                <ScheduleOutlinedIcon className="material-icons-outlined" style={{ marginRight: 10 }} />
                                <Typography variant="body1">{getFormattedTime(event._def.extendedProps?.time)} - {getFormattedTime(event._def.extendedProps?.end_time)}</Typography>
                            </Grid>
                            <Grid container alignItems="center" style={{ marginTop: 10, marginBottom: 15 }}>
                                <SchoolOutlinedIcon className="material-icons-outlined" style={{ marginRight: 10 }} />
                                <Typography variant="body1">{event._def.extendedProps?.teacher_name}</Typography>
                            </Grid>
                            <Grid container alignItems="center" style={{ marginTop: 10, marginBottom: 15 }}>
                                <LocationOnOutlinedIcon className="material-icons-outlined" style={{ marginRight: 10 }} />
                                {
                                    isLink ?
                                        <Typography variant="body1"><a style={{ textDecoration: 'none' }} target="_blank" href={venue}>{event._def.extendedProps?.venue}</a></Typography>
                                        :
                                        <Typography variant="body1">{event._def.extendedProps?.venue}</Typography>
                                }
                            </Grid>
                            <Grid container alignItems="center" style={{ marginTop: 10, marginBottom: 15 }}>
                                <LinkOutlinedIcon className="material-icons-outlined" style={{ marginRight: 10 }} />
                                <Typography variant="body1"><a style={{ textDecoration: 'none' }} target="_blank" href={venue}>{event._def.extendedProps?.zoom_link}</a></Typography>
                            </Grid>

                        </>
                    }
                    <Typography variant="body1" style={{ marginTop: 5, marginBottom: 5, fontSize: 18 }}>Attached materials</Typography>
                    {
                        materials.length > 0 ?
                            <Grid style={{ maxHeight: 180, overflowY: 'scroll' }}>
                                {
                                    materials.map(material => {
                                        return (
                                            <>
                                                <div style={{ backgroundColor: 'white', padding: 10, marginBottom: 10, borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}><DescriptionOutlinedIcon style={{ marginRight: 5, fontSize: 22 }} />{material.title}</div>
                                                    {
                                                        (alreadyRegistered || edit) &&
                                                        <Tooltip title="Download" placement="top">
                                                            <IconButton onClick={() => download(material.aws_name, material.title, 0)} size="small" style={{ backgroundColor: '#009688', color: 'white' }}>
                                                                {
                                                                    downloading ?
                                                                        <CircularProgress style={{ height: 24, width: 24, color: 'white' }} />
                                                                        :
                                                                        <GetAppOutlinedIcon />
                                                                }
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </Grid>
                            :
                            <Typography variant="body2" align="left" style={{ marginTop: 10, color: 'gray' }}>No material attached</Typography>
                    }

                    {
                        (teacher == 'My Classes' && edit) &&
                        <Button onClick={() => handeEditCourse(event)} style={{ width: '100%', borderRadius: 5, backgroundColor: '#1A95AC', color: 'white' }} type="button">Edit Course</Button>
                    }
                </Grid>

                {
                    teacher != 'My Classes' &&
                    <Grid item>

                        <span>{limitExceed}</span>

                        <>
                            {
                                edit ?
                                    <Button onClick={() => handeEditCourse(event)} style={{ width: '100%', borderRadius: 5, backgroundColor: '#1A95AC', color: 'white' }} type="button">Edit Course</Button>
                                    :
                                    (!alreadyRegistered && limitExceed) ?
                                        <Button onClick={() => setLimitPopUp(true)} style={{ width: '100%', borderRadius: 5, backgroundColor: '#1A95AC', color: 'white' }} type="button">Enroll In</Button>
                                        :
                                        alreadyRegistered ?
                                            <Button disabled={true} onClick={enroll} style={{ width: '100%', borderRadius: 5, backgroundColor: '#757575', color: 'white' }} type="submit">Enrolled</Button>
                                            :
                                            <Button disabled={submitting} onClick={() => setPaymentPopUp(true)} style={{ width: '100%', borderRadius: 5, backgroundColor: '#1A95AC', color: 'white' }} type="submit">{submitting ? 'Submitting...' : 'Enroll In'}</Button>
                            }
                        </>

                    </Grid>
                }
            </Grid>
            {
                event != null &&
                <>
                    <MakePayment total={event._def.extendedProps?.class_price} title={event._def?.title} />
                    <LimitExceedPopUp total={event._def.extendedProps?.class_price} title={event._def?.title} />
                </>
            }
        </Grid>
    )
}